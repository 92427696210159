import { Link } from 'react-router'

import { useAuth } from './Provider'
import { AlertError } from '../Alert'
import System from '../Layout/System'

export default function OAuth() {
  const { error, loading } = useAuth()
  return (
    <System>
      <h1>Auth</h1>
      <AlertError message={error} />
      {loading ? <p>Loading...</p> : null}
      <p>
        <Link to="/">Back to Home</Link>
      </p>
    </System>
  )
}
