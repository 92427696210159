import React from 'react'

import * as cookie from 'cookie'
import { createRoot } from 'react-dom/client'

import './index.css'
import App from './App'
import defaultFeatures from './features'
import { mergeFeatures } from './FeatureToggle'

const cookies = cookie.parse(document.cookie)
const cookieFeatures = (cookies.features || '').split(/\s*,\s*/g).filter(Boolean)

const features = mergeFeatures(defaultFeatures, cookieFeatures)

const root = createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <App features={features} />
  </React.StrictMode>,
)
